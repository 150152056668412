import { useAPI, useAuth } from '@/modules';
import { User, UserData } from '@/data/types';
import Creator from '@/pages/creators/profile/Creator.vue';

const { user } = useAuth();

export interface SearchParams {
  limit?: number;
  creatorName?: string;
  skip?: number,
  status?: string;
  category?: string;
  platform?: string;
  language?: string;
  // region?: string;
  country?: string;
  subscriber?: any;
  isUGCCreator?: boolean;
}

export const useCreatorsList = () => {
  const {
    loading,
    finished,
    data: creators,
    execute: sendLoadCreatorsRequest,
  } = useAPI<Array<User>>('/users/creators', {
    parseResponse: (items: Array<UserData>) => items.map((data) => new User(data, user?.value as User)),
  }, false);

  const load = (
    limit = 0,
    creatorName = '',
    skip = 0,
    status = 'all',
    language = '',
  ) => {
    const params: SearchParams = {
      limit,
      creatorName,
      skip,
      status,
      language
    };

    return sendLoadCreatorsRequest({ params })
      .then((result?: Array<User>) => {
        if (result) {
          creators.value = result;
        }
        return creators.value;
      });

  }

  const search = (
    creatorName: string,
    limit: number,
    skip?: number,
    status?: string,
    category?: string,
    platform?: string,
    language?: string,
    // region?: string,
    country?: string,
    subscriber?: any,
    isUGCCreator?: any
  ) => ({
    creatorName,
    limit,
    skip = 0,
    status = 'all',
    language = '',
    // region = '',
    country = '',
    subscriber = [],
    isUGCCreator = false
  }: SearchParams) => {
      load
        (
          limit,
          creatorName,
          skip,
          status,          
          language
        );
    };

  const {
    data: sendLastCampaignsLoadRequestCreators,
    execute: sendLastCampaignsLoadRequest,
  } = useAPI('', {
  }, false);

  const lastCampaignsLoad = (limit: number, searchValue?: string) => sendLastCampaignsLoadRequest({
    url: `/creator/last/campaigns/${limit}`,
    params: { searchValue },
    method: 'GET',
  });

  const {
    data: lastInfluencesLoadData,
    execute: sendLastInfluencersLoadRequest,
  } = useAPI<Array<User>>('', {
  }, false);

  const lastInfluencersLoad = (limit: number, searchValue?: string) => sendLastInfluencersLoadRequest({
    url: `/creator/last/influences/${limit}`,
    params: { searchValue },
    method: 'GET',
  });

  const {
    data: latestCampaignForInfluencer,
    execute: sendLatestCampaignForInfluencerRequest,
  } = useAPI('', {
  }, false);

  const latestCampaign = () => sendLatestCampaignForInfluencerRequest({
    url: `/campaigns/?perspective=creator&limit=4&skip=0&status=alll`,
    params: {},
    method: 'GET',
  });

  return {
    creators,
    loading,
    finished,
    load,
    search,
    // last campaigns active && last
    lastCampaignsLoad,
    sendLastCampaignsLoadRequestCreators,
    // last influencers
    lastInfluencersLoad,
    lastInfluencesLoadData,
    latestCampaignForInfluencer,
    latestCampaign
  };
};
