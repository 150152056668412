import { watch } from 'vue';
import { useAPI } from '@/modules';
import { AppPagePerspective, Brand, CampaignData } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useWhitelistingManager = (perspective?: AppPagePerspective) => {
  const toast = useToastModule();

  // Get Admin Requests
  const {
    loading: checkAdminLoading,
    error: checkAdminError,
    data: checkAdminResult,
    execute: checkAdminRequest,
  } = useAPI('', false);
  const checkAdmin = () =>
    checkAdminRequest({
      url: '/admins',
      method: 'GET',
    });
  watch(checkAdminError, toast.handleRequestError);

  // Get Creators Count
  const {
    loading: getCreatorsCountLoading,
    error: getCreatorsCountError,
    data: getCreatorsCountResult,
    execute: getCreatorsCountRequest,
  } = useAPI('', false);
  const getCreatorsCount = () =>
    getCreatorsCountRequest({
      url: '/admins/stats/count-creators',
      method: 'GET',
    });
  watch(getCreatorsCountError, toast.handleRequestError);

  // Get Campaigns Options
  const {
    loading: getCampaignsOptionsLoading,
    error: getCampaignsOptionsError,
    data: getCampaignsOptionsResult,
    execute: getCampaignsOptionsRequest,
  } = useAPI('', false);

  const getCampaignsOptions = (
    status = 'pending',
    brand = '',
    approvalTab = 'admin'
  ) =>
    getCampaignsOptionsRequest({
      url: `/admins/campaigns/drop-down`,
      method: 'GET',
      params: { status, brand, approvalTab },
    });

  watch(getCampaignsOptionsError, toast.handleRequestError);

  // Get Requests
  const {
    loading: getCpmCampaignApplicationsLoading,
    error: getCpmCampaignApplicationsError,
    data: getCpmCampaignApplicationsResult,
    execute: getCpmCampaignApplicationsRequest,
  } = useAPI('', false);
  const getCpmCampaignApplications = (
    limit: number,
    skip: number,
    status = 'pending',
    filters?: { campaignName: string; username: string }
  ) =>
    getCpmCampaignApplicationsRequest({
      url: '/admins/campaign-pending-request',
      method: 'GET',
      params: {
        limit,
        skip,
        status,
        filters,
      },
    });

  // Get Brands
  const {
    loading: loadingBrands,
    error: loadingBrandsError,
    execute: loadBrandsRequest,
    data: brandsResult,
  } = useAPI<Brand>('', false);

  const getAllBrands = (brandID?: string) =>
    loadBrandsRequest({
      url: `/brands/`,
      method: 'GET',
      params: {
        perspective,
      },
    });
  watch(loadingBrandsError, toast.handleRequestError);

  // Get Export
  const {
    loading: getExportsLoading,
    error: getExportsError,
    data: getExportsResult,
    execute: getExportsRequest,
  } = useAPI('', false);
  const getExports = (
    status = 'pending',
    filters?: { campaignName: string; username: string }
  ) =>
    getExportsRequest({
      url: '/admins/campaign-pending-request/export',
      method: 'GET',
      params: {
        status,
        filters,
      },
      responseType: 'blob',
    });

  // Resolve Requests
  const {
    loading: resolveRequestsProgress,
    error: resolveRequestsError,
    data: resolveRequestsData,
    execute: resolveRequest,
  } = useAPI<CampaignData>('', false);

  const resolveRequestsAction = (
    action: 'accept' | 'reject' | string,
    payload: {
      userId: string;
      campaignId: string;
      applicationId: string;
      channelId: string;
      perspective: 'admin' | 'brand' | string;
      payoutCap: number;
      admin_reason: string;
      brand_reason: string;
      contractWork: boolean;
    }[]
  ) =>
    resolveRequest({
      url: `/admins/campaign-pending-request/${action}`,
      method: 'PUT',
      data: payload,
    });

  // Resolve Requests
  const {
    loading: undoRequestsProgress,
    error: undoRequestsError,
    data: undoRequestsData,
    execute: undoRequest,
  } = useAPI<CampaignData>('', false);

  const undoRequestsAction = (
    payload: {
      campaignId: string;
      applicationId: string;
      channelId: string;
      perspective: 'admin' | 'brand' | string;
    }[]
  ) =>
    undoRequest({
      url: `/admins/campaign-pending-request/undo`,
      method: 'PUT',
      data: payload,
    });

  // Delete Requests
  const {
    loading: deleteRequestsProgress,
    error: deleteRequestsError,
    data: deleteRequestsData,
    execute: deleteRequest,
  } = useAPI<CampaignData>('', false);

  const deleteRequestsAction = (
    payload: {
      userId: string;
      campaignId: string;
      applicationId: string;
    }[]
  ) =>
    deleteRequest({
      url: `/admins/campaign-pending-request`,
      method: 'DELETE',
      data: payload,
    });

  const {
    loading: changeProposedCapLoading,
    error: changeProposedCapError,
    data: changeProposedCapData,
    execute: changeProposedCapRequest,
  } = useAPI<CampaignData>('', false);

  const changeProposedCapAction = (
    payload: {
      userId: string;
      campaignId: string;
      applicationId: string;
      channelId: string;
      perspective: 'admin' | 'brand' | string;
      payoutCap: number;
      admin_reason: string;
      brand_reason: string;
      contractWork: boolean;
    }[]
  ) =>
    changeProposedCapRequest({
      url: '/admins/campaign-pending-request/proposed-cap',
      method: 'PUT',
      data: payload,
    });

  return {
    // get applications for CPM campaigns
    getCpmCampaignApplicationsLoading,
    getCpmCampaignApplicationsError,
    getCpmCampaignApplicationsResult,
    getCpmCampaignApplicationsRequest,
    getCpmCampaignApplications,

    // get creator count
    getCreatorsCount,
    getCreatorsCountResult,
    getCreatorsCountError,
    getCreatorsCountLoading,

    // check admin
    checkAdmin,
    checkAdminResult,
    checkAdminError,
    checkAdminLoading,

    // getAllBrands
    getAllBrands,
    loadingBrands,
    brandsResult,

    // resolveRequestsAction
    resolveRequestsAction,
    resolveRequestsProgress,
    resolveRequestsError,
    resolveRequestsData,

    // deleteRequestsAction
    deleteRequestsAction,
    deleteRequestsProgress,
    deleteRequestsError,
    deleteRequestsData,

    // getCampaignsOptions
    getCampaignsOptions,
    getCampaignsOptionsLoading,
    getCampaignsOptionsError,
    getCampaignsOptionsResult,

    // get Exports
    getExports,
    getExportsLoading,
    getExportsError,
    getExportsResult,

    //undo request
    undoRequestsAction,
    undoRequestsProgress,
    undoRequestsError,
    undoRequestsData,

    //change proposed-cap

    changeProposedCapAction,
    changeProposedCapLoading,
    changeProposedCapError,
    changeProposedCapData,
  };
};
