import { ContentItem, ContentItemData, parseContentItems } from './content-types';
import { Brand, BrandData } from './brand-types';
import { RegionalTierStats, Stats } from './earning-types';

export interface EditUserPayload {
  name: string;
  photo: string;
  description: string;
}

export interface YoutubeData {
  id: string;
  name: string;
  photo: string;
  uploadsPlaylistID?: string;
  followers?: number;
  itemsCount?: number;
}

export interface PaymentData {
  PersonType: string;
  LegalPersonType: string;
  Birthday: number;
  CountryOfResidence: string;
  Nationality: string;
  FirstName: string;
  LastName: string;
  IBAN: string;
  transfers: [string];
  LegalRepresentativeAddress: {
    AddressLine1: string;
    City: string;
    PostalCode: string;
    Country: string;
  };
}

export interface GigapayUserProfile {
  id?: string;
  name?: string;
  email?: string;
  country?: string;
  cellphone_number?: string;
  metadata?: unknown;
}

export interface TrackingLink {
  creatorId: string;
  channelId: string;
  link: string;
  clicks: number;
  qrCode: string;
  // sumary
  conversions: number; // installs
  android: number; // android installs
  IOS: number; // IOS, iPadOS (+) installs
  paidFor: Stats;
  tierOne: RegionalTierStats;
  tierTwo: RegionalTierStats;
  tierThree: RegionalTierStats;
  tierFour: RegionalTierStats;
}

export interface UserData {
  trackingLinks: Array<TrackingLink>;
  id: string;
  name: string;
  email?: string;
  firstName: string;
  lastName: string;
  verified: boolean;
  canUpdateEmail:boolean;
  agencyData: {
    name: string;
    description: string;
    photo: string;
    confirmCode?: string | number;
    address?: {
      AddressLine1?: string;
      City?: string;
      Country?: string;
      PostalCode?: string
    }
  };
  influencerAddress: {
    AddressLine1?: string;
    City?: string;
    Country?: string;
    PostalCode?: string
  };
  isUGCCreator: boolean;
  countries?: string;
  dateOfBirth?: string;
  languages?: string[];
  categories?: string[];
  platforms?: any;
  type: UserType;
  profile: UserProfile;
  isConfirmedAgency: boolean | undefined;

  brands: Array<{
    brand: BrandData;
    role: string;
  }>;
  contentItems: Array<ContentItemData>;

  youtube?: Array<YoutubeData>;
  mangopay?: PaymentData;

  // sub?

  // internal
  source?: string; // dev tools
  canOperateABrand?: boolean;
  creatorAgreeSiteRiles?: boolean;
  agencyWorkers?: [{
    isChannel: boolean;
    channel: {
      channelId: string;
      title: string;
      description: string;
      photo: string;
    };
    user: {
      type: User;
    };
  }];
}

export interface UserProfile {
  photo: string;
  description: string;
}

export enum UserType {
  BrandOwner = 'brandOwner',
  Creator = 'creator',
  Admin = 'admin',
  Agency = 'agencyOwner',
}

export interface AgencyWorkers {
  isChannel: boolean;
  channel: { channelId: string; title: string; description: string; photo: string };
  user: { type: User };
}

export class User {
  public readonly id: string;
  public readonly name: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly verified: boolean;
  public readonly email: string | undefined;
  public readonly profile: UserProfile;
  public readonly type: UserType;
  public readonly brand?: Brand;
  public readonly creatorAgreeSiteRiles?: boolean;
  public readonly canUpdateEmail:boolean;
  public readonly contentItems: Array<ContentItem>;
  public readonly youtube?: Array<YoutubeData>;
  public mangopay?: PaymentData;
  public isConfirmedAgency?: boolean | undefined;
  public countries?: string;
  public dateOfBirth?: string;
  public languages?: string[];
  public categories?: string[];
  public platforms?: any;
  public agencyData: {
    name: string;
    description: string;
    photo: string;
    confirmCode?: string | number;
    address?: {
      AddressLine1?: string;
      City?: string;
      Country?: string;
      PostalCode?: string
    };
  };
  public influencerAddress: {
    AddressLine1?: string;
    City?: string;
    Country?: string;
    PostalCode?: string
  };
  public isUGCCreator?: boolean;
  // eslint-disable-next-line max-len,vue/max-len
  public agencyWorkers: Array<AgencyWorkers> | undefined;
  public trackingLinks?: Array<TrackingLink>;
  private _isAdmin? = false;

  public readonly authUser?: User;
  private campaignsBrand?: Array<{ brand: BrandData; role: string }>;

  constructor(data: UserData, authUser?: User) {
    this.id = data.id;
    this.name = data.name;
    this.verified = data.verified;
    this.profile = data.profile;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.canUpdateEmail = data.canUpdateEmail;
    this.creatorAgreeSiteRiles = data.creatorAgreeSiteRiles;
    this.mangopay = data.mangopay;
    this.contentItems = parseContentItems(data.contentItems || [], this);
    this.youtube = data.youtube;
    this.campaignsBrand = data.brands;
    this.agencyWorkers = data.agencyWorkers;
    this.trackingLinks = data.trackingLinks;
    this.isConfirmedAgency = data?.isConfirmedAgency;
    this.agencyData = data.agencyData;
    this.influencerAddress = data.influencerAddress;
    this.isUGCCreator = data.isUGCCreator;
    this.countries = data?.countries;
    this.dateOfBirth = data?.dateOfBirth;
    this.languages = data?.languages;
    this.categories = data?.categories;
    this.platforms = data?.platforms;
    if (data.type === UserType.Admin) {
      this._isAdmin = true;
      const actAs = localStorage.getItem('actAs') || UserType.Admin;
      data.type = actAs as UserType; // eslint-disable-line no-param-reassign
    }
    this.type = data.type;

    if (data.brands && data.brands.length) {
      const firstBrand: any = data.brands[0].brand;
      if (typeof firstBrand !== 'string') {
        this.brand = new Brand(firstBrand as BrandData, this);
      }
    }

    this.authUser = authUser;
  }

  isBrandOwner(): boolean {
    return this.type === UserType.BrandOwner;
  }

  isMyCompany(campaignId: string): boolean {
    if (this.campaignsBrand?.length === 0) return false;

    const campaign = this.campaignsBrand![0].brand?.campaigns.find((el) => el.toString() === campaignId);
    if (campaign) {
      return true;
    }
    return false;
  }

  isCreator(): boolean {
    return this.type === UserType.Creator;
  }

  isAgencyOwner(): boolean {
    return this.type === UserType.Agency;
  }

  isAdmin(): boolean {
    return this._isAdmin ? true : false;
  }

  isMe(): boolean {
    return this.authUser?.id === this.id;
  }
}
