import React, { useEffect, useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';

import CampaignFilterMenu from '../Other/CampaignFilterMenu/CampaignFilterMenu';
import NewInfluencerItem from "@/react_app/NewLatestItems/NewInfluencerItem/NewInfluencerItem";

import { useAuth, useCommonApi } from '@/modules';

import "./NewCreators.css";
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { useCreatorManager } from '@/modules/creators/creator-manager-module';

const INFLUENCERS_PER_PAGE = 12;


const FavoritesInactive = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <path d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H25.17C26.5508 0.5 27.67 1.61929 27.67 3V25.17C27.67 26.5508 26.5508 27.67 25.17 27.67H3C1.61929 27.67 0.5 26.5508 0.5 25.17V3Z" stroke="white" />
        {/* eslint-disable-next-line max-len,vue/max-len */}
        <path d="M14.3393 18.4148L14.0855 18.2649L13.8314 18.4144L9.73283 20.8256L9.73194 20.8261C9.33493 21.0608 8.91289 20.7016 9.00121 20.3307L10.0875 15.7785L10.1589 15.4794L9.92418 15.2808L6.29962 12.2136L6.29944 12.2135C5.99283 11.9543 6.13666 11.4403 6.5783 11.4059L6.5783 11.406L6.58088 11.4057L11.3511 11.0103L11.649 10.9856L11.7683 10.7114L13.6349 6.41938L13.6357 6.41741C13.7981 6.03957 14.3718 6.03957 14.5342 6.41741L14.5349 6.419L16.4015 10.7207L16.5206 10.9952L16.8189 11.0199L21.5891 11.4154L21.5917 11.4156C22.0333 11.4499 22.1771 11.9639 21.8705 12.2231L21.8703 12.2233L18.2458 15.2904L18.0111 15.489L18.0824 15.7882L19.1688 20.3404C19.2571 20.7113 18.835 21.0705 18.438 20.8358L18.4379 20.8357L14.3393 18.4148Z" stroke="white" />
      </svg>
    </div>
  )
}

const FavoritesActive = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <path d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H25.17C26.5508 0.5 27.67 1.61929 27.67 3V25.17C27.67 26.5508 26.5508 27.67 25.17 27.67H3C1.61929 27.67 0.5 26.5508 0.5 25.17V3Z" stroke="white" />
        {/* eslint-disable-next-line max-len,vue/max-len */}
        <path d="M14.826 19.1974L18.9246 21.6183C19.6752 22.0619 20.5936 21.4061 20.3961 20.5766L19.3097 16.0241L22.9343 12.957C23.596 12.3976 23.2405 11.3367 22.3714 11.2691L17.6012 10.8737L15.7346 6.57202C15.3988 5.79077 14.2531 5.79077 13.9173 6.57202L12.0507 10.8641L7.28054 11.2595C6.41144 11.327 6.0559 12.388 6.7176 12.9474L10.3422 16.0145L9.25578 20.5669C9.05826 21.3964 9.97674 22.0523 10.7273 21.6086L14.826 19.1974Z" fill="#E73D38" />
      </svg>
    </div>
  )
}

export default function (props) {

  const user = useAuth();
  const { type } = user.user.value;
  const { manager: { getMasterData } } = useCommonApi();
  const creatorManager = useCreatorManager();

  const [searchValue, setSearchValue] = useState('');
  const [creators, setCreators] = useState([]);
  const [first, setFirst] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [favoritesFilter, setFavoritesFilter] = useState(false);
  const [time, setTime] = useState("");
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [languageList, setLanguageList] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [influencerCategoryOptions, setInfluencerCategoryOptions] = useState([]);
  const [isExporting, setIsExporting] = useState(false);


  const [extendedCategories, setExtendedCategories] = useState({
    language: false,
  });

  const [creatorFilters, setCreatorFilters] = useState({
    language: [],
  });

  useEffect(() => {
    if (props.creators) {
      setCreators(props.creators);
    }
  }, [props.creators]);

  useEffect(() => {
    if (platformOptions.length <= 0) {
      getOptionsList();
    }
  }, [])


  useEffect(() => {
    if (creatorFilters) {
      setFirst(0);
      props.getCreators(
        0,
        searchValue,
        0,
        'all',
        creatorFilters?.language?.map(item => item.id)?.join(','),
      )
    }
  }, [creatorFilters])

  const getOptionsList = () => {
    setIsLoadingOptions(true);
    getMasterData('').then((res) => {
      if (res) {
        setIsLoadingOptions(false);
        setLanguageList(res.data.languages.map(language => {
          return {
            value: language.id, name: language.name, id: language.id, code: language.code, isActive: language.isActive
          }
        }));
        setPlatformOptions(res.data.platforms.map(platform => {
          return {
            platform: platform.name, value: [""], name: platform.name, platformId: platform.id, id: platform.id, isActive: platform.isActive
          }
        }).filter(platform => platform.name !== "YouTube"));       
      } else {
        setLanguageList([]);
        setPlatformOptions([]);
      }
    }).catch(error => {
      setIsLoadingOptions(false);
    });
  }

  const onPageChange = (event) => {
    setFirst(event.first);
    props.getCreators(
      0,
      searchValue,
      event.first,
      'all',
      creatorFilters?.language?.map(item => item.id)?.join(','),

    )
  };

  const handleSearch = (e) => {
    clearTimeout(time);
    const searchValue = e.target.value.toLowerCase();
    setSearchValue(searchValue);
    setTime(
      setTimeout(() => {
        setFirst(0);
        props.getCreators(
          0,
          searchValue,
          0,
          'all',
          creatorFilters?.language?.map(item => item.id)?.join(','),
        )
      }, 800)
    );
  };

  const filterFavorites = () => {
    if (favoritesFilter) {
      setCreators(props.creators);
      setFavoritesFilter(false);
    } else {
      setCreators(
        creators.filter((item) =>
          props.favorites?.allUserFavorites.some((favorite) =>
            favorite.influences?.id === item.id))
      );
      setFavoritesFilter(true);
    }
  }

  const toggleExtended = (category) => {
    setExtendedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleCheckboxChange = (item, category) => {
    setCreatorFilters((prev) => {
      const currentItems = prev[category];
      const updatedItems = currentItems?.includes(item)
        ? currentItems?.filter((i) => i !== item)
        : [...currentItems, item];
      return { ...prev, [category]: updatedItems };
    });
  };
  const handleGetExports = () => {
    setIsExporting(true);
    creatorManager.exportCreatorList(searchValue, creatorFilters.language, 'all').then((exports) => {
      if (exports) {
        const blob = new Blob([exports], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'Influencers.xlsx');
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
      } else {
        setIsExporting(false);
      }
    }).catch((error) => {
      console.log('error', error)
    });
  };

  return (
    <div className={'creators'}>
      <div className={'titleRow'}>
        <p className={'title'}>Influencers ({props.creators?.length})</p>
        <div className={'saved'} onClick={() => filterFavorites()}>
          {favoritesFilter ?
            <FavoritesActive /> : <FavoritesInactive />
          }
          Saved
        </div>
      </div>
      <div className={'content'}>
        <CampaignFilterMenu
          platformOptions={[]}
          languageOptions={languageList}
          extendedCategories={extendedCategories}
          toggleExtended={toggleExtended}
          campaignFilters={creatorFilters}
          handleCheckboxChange={handleCheckboxChange}
          showDefaultFilter={true}
        />
        <div className={'creatorsResult'}>
          <div className={'searchBar'}>
            <div className='searchWrapper'>
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText placeholder="Search for influencer" onChange={(e) => handleSearch(e)} value={searchValue} />
              </span>
              <Button className='exportButton'
                icon="pi pi-file-export" loading={isExporting}
                onClick={handleGetExports}>Export</Button>
            </div>
            {!noResults &&
              <Paginator first={first} rows={INFLUENCERS_PER_PAGE} totalRecords={creators.length} onPageChange={onPageChange} template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }} />
            }
          </div>
          <div className={'creatorsGrid'}>
            {creators?.slice(first, first + INFLUENCERS_PER_PAGE).map((creator, index) =>
              <div key={index} className={'creatorItem'}>
                <NewInfluencerItem influencer={creator} addToFavorites={props.addToFavorites} favorites={props.favorites}
                  goToProfile={props.goToInfluencerProfile} userType={type} />
              </div>)}
            {noResults &&
              <p className={'noResult'}>There are no creators matching the current search.</p>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
