
import { defineComponent, ref } from 'vue';
import { AppPageConfig, AppPageProps } from '@/data/types';
import { usePage, useCreators, useFavorites, useAuth } from '@/modules';
import NewCreators from '@/react_app/NewCreators/NewCreators';
import OldCreators from '@/pages/creators/OldCreators.vue';
import { applyPureReactInVue } from 'veaury';
import { isInfluencersPageEnabled } from '@/utils/globals/feature-flags';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Creators',
  components: {
    NewCreators: applyPureReactInVue(NewCreators),
    OldCreators,
  },
  props: {
    limit: {
      type: Number,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    // new
    ...AppPageProps,
  },

  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: '',
      search: {
        enabled: props.showSearch,
        defaultType: 'creators',
        placeholder: 'Search for influencer',
      },
      ...props,
    });

    const controller = useCreators(config.value.viewPerspective);

    const loadPageData = (
      limit?: number,
      name?: string,
      skip?: number,
      status?: string,
      language?: string
    ) =>
      controller.list.load(limit || props.limit, name, skip, status, language);

    const getCreators = async (
      limit?: number,
      name?: string,
      skip?: number,
      status?: string,
      language?: string
    ) => {
      await loadPageData(limit, name, skip, status, language);
    };

    const clearSearch = async () => {
      await loadPageData();
    };

    const router = useRouter();
    const favoritesManager = useFavorites();
    const userInf = useAuth();
    const userFavorites = ref();

    const goToInfluencerProfile = (id: string) =>
      router.push({ path: `${id}` });

    if (userInf.user.value) {
      favoritesManager
        .getUSerFavorites('influences', userInf.user.value.id)
        .then((favorites) => {
          userFavorites.value = favorites;
        });
    }

    const addToFavorites = async (influencerId: string) => {
      if (userInf.user.value) {
        const item = await favoritesManager.likeOrDislike(
          'influences',
          userInf.user.value?.id,
          influencerId
        );
        if (item.type === 'delete') {
          const index = userFavorites.value.allUserFavorites.findIndex(
            (el: any) => el.influences?.id === influencerId
          );
          userFavorites.value.allUserFavorites.splice(index, 1);
        } else if (item.type === 'create') {
          userFavorites.value.allUserFavorites.push({
            influences: { id: influencerId },
            type: 'influences',
          });
        }
      }
    };

    return {
      clearSearch,
      config,
      loading: controller.list.loading,
      creators: controller.list.creators,
      performSearch: controller.list.search('name', props.limit || 0),
      isInfluencersPageEnabled,
      goToInfluencerProfile,
      addToFavorites,
      favorites: userFavorites,
      getCreators,
    };
  },
});
